<template>
  <div>
    <b-card v-if="showScreen">
            <b-table
              striped
              hover
              sort-icon-left              
              :items="sortedFrameworkDataData"
              :fields="getFieldsToShowData"
              class="workplanindex"
              @row-clicked="rowClickHandler"
            >
              <template #cell(objective_code)="data">
                <b>{{data.item.objective_code}}</b><br/>
                {{data.item.objective}}
              </template>       
              <template #cell(not_applicable)="data">
                 <div v-if="notApplicable(data.item)" style="text-align:center; width:100%"><span v-html="notApplicableText(data.item)"></span></div>
              </template>      
              <template #cell(applicable)="data">
                 <span v-bind:key="index" v-html="showSOADataAll(data.item)" style=""></span>
              </template>                                         
              <template v-for="( field, index)  in getFieldsToTemplateData" v-slot:[`cell(${field.id})`]="data"><span v-bind:key="index" v-html="showSOAData(data.item,field)" style=""></span></template> 
            </b-table>

    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { GET_FRAMEWORK } from "@/core/services/store/framework.module";
import { GET_FRAMEWORKEXCEPTIONS} from "@/core/services/store/frameworkexceptions.module";
import {
  GET_SYSTEMS,
} from "@/core/services/store/systems.module";
import {
  GET_THREATS,
} from "@/core/services/store/threats.module";

export default {
  name: "soa",
  components: {
  },
  data() {
    return {
      getFieldsToTemplateData:[],
      getFieldsToShowData:[],
      sortedFrameworkDataData: [],
      showScreen: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Statement of Applicability" }]);
    this.getData();
  },
  methods: {
    getData() {
      console.log('getdata')      
      var frameworkID = '';
      if (this.currentFramework.framework) {
        frameworkID = this.currentFramework.framework.id;
      }

      this.$store.dispatch(GET_FRAMEWORKEXCEPTIONS, {framework_id: this.selectedFramework});
      this.$store.dispatch(GET_FRAMEWORK,frameworkID);
      this.$store.dispatch(GET_SYSTEMS);
      this.$store.dispatch(GET_THREATS);
      //this.$store.dispatch(GET_FRAMEWORKEXCEPTIONS, {framework_id: frameworkID});      

      setTimeout(() => {
          this.showScreen = true;
          this.getFieldsToTemplateData = this.getFieldsToTemplate();
          this.getFieldsToShowData = this.getFieldsToShow();
          this.sortedFrameworkDataData = this.sortedFrameworkData();
      }, 1000);  
      
    },
    notApplicable(data) {

      var retVal = false;
      this.currentFRAMEWORKEXCEPTIONsData.forEach(element => {
      console.log('notApplicable',element.control_id ,data.objective_code)

        if (element.control_id == data.objective_code) {retVal = true;}
        
      });
      return retVal;
    },
    notApplicableText(data) {

      var retVal = '';
      this.currentFRAMEWORKEXCEPTIONsData.forEach(element => {
        if (element.control_id == data.objective_code) {retVal = retVal + element.reference_id + '- ' +element.description + '<br/>'}
        
      });
      return retVal;
    },
    showSOAData(data,field) {

      console.log('showSOAData')
      var showTick = false;
      this.currentThreatsData.forEach(threat => {

        var systemmatch = false;
        var controlmatch = false;
        if (threat.frameworks) {

          threat.frameworks.forEach(framework => {
            if ( framework.id == data[field.id].control ) {
              controlmatch  =true;
            }
            
          });

        }
        if (threat.systems) {
          threat.systems.forEach(system => {
            if ( system.id == field.id ) {
              systemmatch  =true;
            }
            
          });
        }
        if (systemmatch && controlmatch) {
          showTick = true;
        }

      });

      if (showTick) {
        return '&#10004;'
      } else {
        return '';
      }

    },
    showSOADataAll(data) {

      console.log('showSOADataAll' , data, this.getFieldsToTemplateData)
      var showTick = false;
      this.currentThreatsData.forEach(threat => {

        var systemmatch = false;
        var controlmatch = false;
        if (threat.frameworks) {

          this.getFieldsToTemplateData.forEach(field => {
            threat.frameworks.forEach(framework => {
              if ( framework.id == data[field.id].control ) {
                controlmatch  =true;
              }
              
            });
          })



        }
        if (threat.systems) {

          this.getFieldsToTemplateData.forEach(field => {
            threat.systems.forEach(system => {
              if ( system.id == field.id ) {
                systemmatch  =true;
              }
              
            });
          })



        }
        if (systemmatch && controlmatch) {
          showTick = true;
        }

      });

      if (showTick) {
        return '&#10004;'
      } else {
        return '';
      }

    },    
    sortedFrameworkData() {
      console.log('sortedFrameworkData')
      var results =this.currentFramework.framework_items;
      var systems = this.currentSystemsData;
      //var threats = this.currentThreatsData;
      var returnResults = [];

      if (results) {
        results = results.sort((a, b) => ( String('00000000' + a.objective_code).slice(-7) > String('00000000' + b.objective_code).slice(-7)) ? 1 : -1);



        if (systems.length > -1) {

          systems = systems.sort((a, b) => ( a.name > b.name ? 1 : -1));

        }

        results.forEach(result => {

          var baseRecord = {
            id: result.id,
            objective_code: result.objective_code,
            objective: result.objective,
          }; 

          systems.forEach(system => {



            this.$set(baseRecord, system.id , { system: system.id, control: result.id });
          
          });



          returnResults.push(baseRecord)
          
        });
      }

      return returnResults;
    },
    getFieldsToShow() {
      console.log('getFieldsToShow')

      var requirementFields = [
        { key: "objective_code", label: "Control", sortable: false },
        { key: "not_applicable", label: "Not Applicable", sortable: false ,class: 'left-border narrowcol'},
        { key: "applicable", label: "Applicable", sortable: false ,class: 'left-border'},
      ];

      var systems = this.currentSystemsData;

      if (systems.length > -1) {

        systems = systems.sort((a, b) => ( a.name > b.name ? 1 : -1));

        systems.forEach(system => {

          requirementFields.push({ key: system.id,  label: ( system.name + ' (' + system.category + ')'), thClass: 'rot-text' ,class: 'left-border'  });
          
        });

      }
      return requirementFields;
    },
    getFieldsToTemplate() {
      console.log('getFieldsToTemplate')      
      var systems = this.currentSystemsData;

      if (systems.length > -1) {

        systems = systems.sort((a, b) => ( a.name > b.name ? 1 : -1));


      }
      return systems;
    }        


  },
  computed: {
    ...mapGetters(["currentThreatsData","currentFramework","currentSystemsData","currentFRAMEWORKEXCEPTIONsData"]),
  },
  
};
</script>

<style>

.left-border {
  border-left: #ccc 1px solid;
}

th.narrowcol {

}

th.left-border {
  border-left: #ccc 0px solid;  
}

th.rot-text {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}

th.rot-text > div {
  transform: 
    /* Magic Numbers */

    /* 45 is really 360 - 45 */
    rotate(315deg);
  width: 30px;
}

</style>